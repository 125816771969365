import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected, //import here
  mintNFT,
  presaleMint,
  claim,
  checkMintSupply,
  checkClaimSupply,
  checkPresaleUsed,
  checkClaimList,
  checkMaxTx,
  checkPresaleActive,
  checkSaleActive,
  checkClaimActive,
  checkNetwork
} from "./utils/interact.js";
import React from 'react';
let isWhiteList = false; 
let isHolder = false;

const Minter = (props) => {
  
  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [mintstatus, setMintStatus] = useState("0");
  const [claimStatus, setClaimStatus] = useState("0");
  const [quantity, setQty] = useState("1");
  const [maxTx, setMaxTx] = useState("0");
  var whiteList = [];
 
  useEffect(async () => {
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status); 
    const mintstatusResponse = await checkMintSupply();
    setMintStatus(mintstatusResponse); 
    const claimstatusResponse = await checkClaimSupply();
    setClaimStatus(claimstatusResponse); 
    const maxTx = await checkMaxTx();
    setMaxTx(maxTx);
    await checkNetwork();
    ////Presale check
    //await checkPresaleActive(); 
    //checkWL();
    
    ////Public sale check
    await checkSaleActive();

    ////claim check
    //await checkClaimActive();
    //await checkClaimList();

    addWalletListener();
  }, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onSaleQtyChange = async (qty) => {
    if(qty > maxTx || isNaN(qty)){
      qty = maxTx;
    }
    setQty(qty);
    document.getElementById("mintQty").value = qty;
  };

  const onMintPressed = async () => {
    const mintstatusResponse = await checkMintSupply();
    setMintStatus(mintstatusResponse); 
    if(mintstatusResponse==0){
      //hideMintForm();
      setStatus("Sold out.");
    }else{
      const { status } = await mintNFT(parseInt(quantity));
      setStatus(status);
    }
  };

  const checkWL= async () => { 
    if(window.ethereum && window.ethereum.selectedAddress!=null){
      //check WL
      const whiteListCheck = whiteList.find(element => {
        return element.toLowerCase() === window.ethereum.selectedAddress.toLowerCase();
      });
      
      if(whiteListCheck == undefined){
        //setStatus("⛔️ You're not in the whitelist.");
        hideMintForm();
        isWhiteList = false;
      }else{
        hideCheckHolderForm();
        setStatus("");
        isWhiteList = true;
        const checkWL = await checkPresaleUsed();
      }
    }
  }

  const onPresaleMintPressed = async () => {

    if(window.ethereum.selectedAddress==null){
      setStatus("🦊 Connect to Metamask using the top right button!");
    }else{
      checkWL();
      
      console.log("isWhiteList:"+isWhiteList);
      console.log("isHolder:"+isHolder);
      if(isWhiteList||isHolder){
        const mintstatusResponse = await checkMintSupply();
        setMintStatus(mintstatusResponse); 
        
        if(mintstatusResponse==0){
          hideMintForm();
          setStatus("Sold out.");
        }else{
          const { status, success } = await presaleMint(parseInt(quantity));
          setStatus(status);
          if(success){
            hideMintForm();
          }
        }
      }
      
    } 
  };

  const onClaimPressed = async () => {
    if(window.ethereum.selectedAddress==null){
      setStatus("🦊 Connect to Metamask using the top right button!");
    }else{
      const claimstatusResponse = await checkClaimSupply();
      setMintStatus(claimstatusResponse); 
      if(claimstatusResponse==0){
        setStatus("Claimed out.");
      }else{
        const { status } = await claim();
        setStatus(status);
      }
    }
  };

  function addWalletListener() {
    if (window.ethereum) {
      if(window.ethereum.selectedAddress==null){
        //hideMintForm();
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top right button.");
      }
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
          document.getElementById("alert").innerHTML="";
          //checkWL();
        } else {
          //hideMintForm();
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      //hideMintForm();
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }
  
  function hideMintForm(){
    document.getElementById("mint-form").style.height = 0;
    document.getElementById("mint-form").style.visibility = "hidden";
  }

  function showMintForm(){
    document.getElementById("mint-form").style.height = 'auto';
    document.getElementById("mint-form").style.visibility = "visible";
  }
  
  function hideCheckHolderForm(){
    document.getElementById("check-holders-form").style.height = 0;
    document.getElementById("check-holders-form").style.visibility = "hidden";
  }

  function showCheckHolderForm(){
    document.getElementById("check-holders-form").style.height = 'auto';
    document.getElementById("check-holders-form").style.visibility = "visible";
  }

  return (
    
    
    <div className="Minter">
      <div id="wallet-bar">
        <div  className="logo">
          <img src="/0x0.svg" />
        </div>
        <button id="walletButton" onClick={connectWalletPressed}>
          {walletAddress.length > 0 ? (
            "Connected: " +
            String(walletAddress).substring(0, 6) +
            "..." +
            String(walletAddress).substring(38)
          ) : (
            <span>Connect Wallet</span>
          )}
        </button>
      </div>
      
      <div className="heroimg">
        <img src="/heroimg.jpg" />
      </div>
      
      <h1 id="title">0x0DAO Vol.4 - itsucks</h1>
      
      <div id="mint-form">
        <p className="price">
          Presale price: 0.096 ETH
        </p>
        <p>
          Enter the quantity you want, then press "Mint" (max. {maxTx})
        </p>
        <form>
          <input
            id="mintQty"
            type="text"
            placeholder=""
            defaultValue = {quantity}
            onBlur={(event) => onSaleQtyChange(parseInt(event.target.value,10))}
          />
        </form>
        <p id="mintSupplyStatus">
          <span>{mintstatus} left to mint</span>
        </p>
        <button id="mintButton" onClick={onMintPressed}>
          Mint
        </button>
      </div>

    <div id="status">
      <span>{status}</span>
    </div>
    <div id="alert">
      <span></span>
    </div>
    <div id="smart-contract">
      Verified smart contract on Etherscan <a href="https://etherscan.io/address/0xf1944301a68f226796c4f6A46b98B149dCBBffC1" target={"_blank"}>0xf1944301a68f226796c4f6A46b98B149dCBBffC1</a>.
    </div>
  </div>
  );
};

export default Minter;
