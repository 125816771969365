//require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMYKEY;
const wlSignKey = process.env.REACT_APP_WL_SIGN_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey); 

const contractABI = require('../ZeroXZeroFactory.json')
const contractAddress = "0xf1944301a68f226796c4f6A46b98B149dCBBffC1";

const presalePrice = 69000000000000000;
const price = 96000000000000000;
const ticketPrefix = "mm2";

export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          //status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "🚨 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            //status: "👆🏽 Write a message in the text-field above.",
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "🚨 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  export const mintNFT = async(qty) => {
    //error handling
    if ((qty == 0) || (Number.isNaN(qty))) { 
      return {
          success: false,
          status: "❗Please enter a number.",
      }
    }
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const gasEstimate = await window.contract.methods.buy(qty).estimateGas({from: window.ethereum.selectedAddress, value: price*qty}, function(error, gasAmount){
      if(error!=null){
        document.getElementById("alert").innerHTML=error.message.replace('Returned error: execution reverted: ','');
        hideMintForm();
      }
    });
    //set up your Ethereum transaction
    const transactionParameters = {
      from: window.ethereum.selectedAddress,
      to: contractAddress,
      value: web3.utils.numberToHex(price*qty),
      gasLimit: Math.floor(gasEstimate*1.2),
      data: window.contract.methods.buy(qty).encodeABI()
    };
    //sign the transaction via Metamask
    try {
    const txHash = await window.ethereum
      .request({
          method: 'eth_sendTransaction',
          params: [transactionParameters],
      });
    return {
      success: true,
      status: (
        <span>
          <p>
            {" "}
            ✅{" "}Check out your transaction on Etherscan:{" "}
            <a target="_blank" href={`https://etherscan.io/tx/`+txHash}>
              {`https://etherscan.io/tx/`+txHash}
            </a>
          </p>
        </span>
      )
    }
    } catch (error) {
    return {
      success: false,
      status: "🚨 " + error.message
    }

    }

  };

  export const presaleMint = async(qty) => {
    //error handling
    if ((qty == 0) || (Number.isNaN(qty))) { 
      return {
          success: false,
          status: "❗Please enter a number.",
      }
    }
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    //WL signature
    const ticket = ticketPrefix + window.ethereum.selectedAddress;
    const hash = web3.utils.soliditySha3(ticket, window.ethereum.selectedAddress);
    const sig = web3.eth.accounts.sign(hash, '0x' + wlSignKey);
    // estimate gas
    const gasEstimate = await window.contract.methods.presale(web3.utils.asciiToHex(ticket), sig.signature, qty).estimateGas({from: window.ethereum.selectedAddress, value: presalePrice*qty}, function(error, gasAmount){
        if(error!=null){
          if(error.message === "Returned error: execution reverted: ticket has already been used"){
            document.getElementById("alert").innerHTML="🚨 You have minted in presale.";
            hideMintForm();
          }else{
            document.getElementById("alert").innerHTML=error.message.replace('Returned error: execution reverted: ','');
            hideMintForm();
          }
        }else{
          //document.getElementById("status").innerHTML='🛂 You are in the white list.';
        }
    });
    
    //set up your Ethereum transaction
    const transactionParameters = {
      from: window.ethereum.selectedAddress,
      to: contractAddress,
      value: web3.utils.numberToHex(presalePrice*qty),
      gasLimit: Math.floor(gasEstimate*1.2),
      data: window.contract.methods.presale(web3.utils.asciiToHex(ticket), sig.signature, qty).encodeABI()
    };
    //sign the transaction via Metamask
    try {
      const txHash = await window.ethereum
      .request({
          method: 'eth_sendTransaction',
          params: [transactionParameters],
      });
      return {
        success: true,
        status: (
          <span>
            <p>
              {" "}
              ✅{" "}Check out your transaction on Etherscan:{" "}
              <a target="_blank" href={`https://etherscan.io/tx/`+txHash}>
                {`https://etherscan.io/tx/`+txHash}
              </a>
            </p>
          </span>
        )
      }
    } catch (error) {
      return {
        success: false,
        status: "🚨 " + error.message
      }
    }

  };

  export const claim = async(qty) => {
    //error handling
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const gasEstimate = await window.contract.methods.claim().estimateGas({from: window.ethereum.selectedAddress}, function(error, gasAmount){
      if(error!=null){
        document.getElementById("alert").innerHTML=error.message.replace('Returned error: execution reverted: ','');
        if(document.getElementById("alert").innerHTML = 'ONLY HOLDERS'){
          //document.getElementById("alert").innerHTML='You are not on the free mint list.';
        }
      }
    });
    //set up your Ethereum transaction
    const transactionParameters = {
      from: window.ethereum.selectedAddress,
      to: contractAddress,
      value: 0,
      gasLimit: Math.floor(gasEstimate*1.2),
      data: window.contract.methods.claim().encodeABI()
    };
    //sign the transaction via Metamask
    try {
    const txHash = await window.ethereum
      .request({
          method: 'eth_sendTransaction',
          params: [transactionParameters],
      });
    return {
      success: true,
      status: (
        <span>
          <p>
            {" "}
            ✅{" "}Check out your transaction on Etherscan:{" "}
            <a target="_blank" href={`https://etherscan.io/tx/`+txHash}>
              {`https://etherscan.io/tx/`+txHash}
            </a>
          </p>
        </span>
      )
    }
    } catch (error) {
    return {
      success: false,
      status: "🚨 " + error.message
    }

    }

  };

  export const checkPresaleActive = async() => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const presaleActive = await window.contract.methods.presaleActive.call().call();
    if(!presaleActive){
      document.getElementById("alert").innerHTML="🚨 Presale is not active.";
      hideMintForm();
    }
  };

  export const checkSaleActive = async() => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    await window.contract.methods.saleActive.call().call(
      function(error, saleActive){
        if(!saleActive){
          document.getElementById("alert").innerHTML="🚨 Sale is not active.";
          hideMintForm();
        }else{
          showMintForm();
        }
      }
    );
  };

  export const checkClaimActive = async() => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const claimActive = await window.contract.methods.claimActive.call().call();
    if(!claimActive){
      document.getElementById("alert").innerHTML="🚨 Claim is not active.";
      hideClaimForm();
    }else{
      showClaimForm();
    }
  };

  export const checkMaxTx = async() => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const maxTx = await window.contract.methods.maxTx.call().call();
    return maxTx;
  };

  export const checkMintSupply = async() => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const mintSupply = await window.contract.methods.mintSupply.call().call();
    return mintSupply;
  };

  export const checkClaimSupply = async() => {
    if(window.ethereum && window.ethereum.selectedAddress!=null){
      window.contract = await new web3.eth.Contract(contractABI, contractAddress);
      const claimSupply = await window.contract.methods.claimSupply.call().call();
      return claimSupply;
    }
  };

  export const checkClaimList = async() => {
    if(window.ethereum && window.ethereum.selectedAddress!=null){
      window.contract = await new web3.eth.Contract(contractABI, contractAddress);
      const gasEstimate = await window.contract.methods.claim().estimateGas({from: window.ethereum.selectedAddress, value: 0}, function(error, gasAmount){
        if(error!=null){
          if(error.message === "Returned error: execution reverted: ONLY HOLDERS"){
            //document.getElementById("alert").innerHTML="🚨 You are not on the free mint list.";
            hideClaimForm();
          }else{
            document.getElementById("alert").innerHTML="🚨 " + error.message.replace('Returned error: execution reverted: ','');
            hideClaimForm();
          }
        }else{
          console.log('pass');
        }
      });
    }else{
      hideClaimForm();
    }
  };

  export const checkPresaleUsed = async() =>{
    if(window.ethereum && window.ethereum.selectedAddress!=null){
      //WL signature
      const ticket = ticketPrefix + window.ethereum.selectedAddress;
      const hash = web3.utils.soliditySha3(ticket, window.ethereum.selectedAddress);
      const sig = web3.eth.accounts.sign(hash, '0x' + wlSignKey);

      window.contract.methods.presale(web3.utils.asciiToHex(ticket), sig.signature, 1).estimateGas({from: window.ethereum.selectedAddress, value: presalePrice*1}, function(error, gasAmount){
          if(error!=null){
            if(error.message === "Returned error: execution reverted: ticket has already been used"){
              document.getElementById("alert").innerHTML="🚨 You have minted in presale.";
              hideMintForm();
            }else{
              document.getElementById("alert").innerHTML="🚨 " + error.message.replace('Returned error: execution reverted: ','');
              hideMintForm();
            }
          }else{
            showMintForm();
          }
      });
    }
  };

  export const checkNetwork = async () => {
    if (window.ethereum) {
      const chainId = 1 // Mainnet

      if (window.ethereum.networkVersion !== chainId) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(chainId) }]
          });
        } catch (err) {
          
        }
      }
    }
  };

  function hideMintForm(){
    document.getElementById("mint-form").style.height = 0;
    document.getElementById("mint-form").style.visibility = "hidden";
  }

  function showMintForm(){
    document.getElementById("mint-form").style.height = 'auto';
    document.getElementById("mint-form").style.visibility = "visible";
    document.getElementById("alert").innerHTML="";
  }

  function hideClaimForm(){
    document.getElementById("claim-form").style.height = 0;
    document.getElementById("claim-form").style.visibility = "hidden";
  }

  function showClaimForm(){
    document.getElementById("claim-form").style.height = 'auto';
    document.getElementById("claim-form").style.visibility = "visible";
  }

  function hideHoldersCheckForm(){
    document.getElementById("check-holders-form").style.height = 0;
    document.getElementById("check-holders-form").style.visibility = "hidden";
  }

  function showHoldersCheckForm(){
    document.getElementById("check-holders-form").style.height = 'auto';
    document.getElementById("check-holders-form").style.visibility = "visible";
  }